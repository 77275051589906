import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteSessionData, getSessionData } from "../utils/storageFunctions";
import { server, staffEndpoints } from "../services";
import PageHeader from "../components/PageHeader";
import PropTypes from 'prop-types';
import AdminManagementControls from "../components/AdminManagementControls";

const StaffPortal = () => {
  const [userInfo, setUserInfo] = useState(null);
     const [staffs, setStaffs] = useState(null);
     const navigate = useNavigate();
     
     const logout = () => {
          deleteSessionData('staff');
          return navigate('/login');

     }

     const fetchUsers = async() => {
          const plts = await server.get(staffEndpoints.staffMember.findAll);
          if(plts && plts.data) {
               setStaffs(plts.data);
          }
     }

     const fetchUserInfo = () => {
          const userData = getSessionData('staff');
          if(userData){
               setUserInfo(userData);
          }else{
               navigate('/login');
          }
     }

     useEffect(() => {
          fetchUserInfo();
     },[]) ;

     useEffect(() => {
          (async() => await fetchUsers())();
     },[userInfo]);
  return (
    <div className="page-container">
      <PageHeader content={{title: "Huza250 Staff Portal", user: userInfo, logout}} />
      {userInfo && userInfo.role === "admin" ? <AdminManagementControls /> : null}
      <div className="platforms-container">
        <StaffCard content={{name: "Dashboards",dest: "/dashboard"}} />
        <StaffCard content={{name: "Tasks", dest: "/tasks"}} />
        <StaffCard content={{name: "Reports", dest: "/reports"}} />
        <StaffCard content={{name: "Remarks", dest: "/remarks"}} /> 
      </div>
    </div>
  )
}

const StaffCard = ({content}) => {
  const navigate = useNavigate();
  return (
    <div className="staff-nav-card" onClick={() => navigate(content.dest)} >
      <h3>{content.name}</h3>
    </div>
  )
}

StaffCard.propTypes = {
  content: PropTypes.object
}
export default StaffPortal