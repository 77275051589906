import { useNavigate } from "react-router-dom"

const AdminManagementControls = () => {
     const navigate = useNavigate();
     return (
          <div className="admin-management-controls-container">
               <div className="control-card">
                    <p onClick={() => navigate('/staff-management')}>Manage Staff</p>
               </div>
               <div className="control-card">
                    <p onClick={() => navigate('/platforms')}>Manage Platforms</p>
               </div>
               <div className="control-card">
                    <p>View Reports</p>
               </div>
               <div className="control-card">
                    <p>Check Actions</p>
               </div>
          </div>
     )
}

export default AdminManagementControls