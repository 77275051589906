export const storeSessionData = (key, data) => {
     sessionStorage.setItem(key, stringfyData(data));
}

export const deleteSessionData = (key) => {
     sessionStorage.removeItem(key);
} 
export const getSessionData = (key) => {
     try {
          const data = sessionStorage.getItem(key);
          if(data){
               return parseData(data);
          }else {
               return null
          }
     } catch (error) {
          console.log(error);
          return null;
     }
}

export const storeAppData = (key,data) => {
     try {
          localStorage.setItem(key, stringfyData(data));
     } catch (error) {
          console.log(error);
     }
}

export const getAppData = (key) => {
     try {
          const data = localStorage.getItem(key);
          if(data){
               return parseData(data);
          }else{
               return null;
          }
     } catch (error) {
          console.log(error);
          return null;
     }
} 

export const deleteAppData = (key) => {
     localStorage.removeItem(key);
}

export const stringfyData = (data) =>{
     try {
          if(data){
               if(typeof(obj) === 'string'){
                    return data
               }else{
                    return JSON.stringify(data)
               }
          }else{
               return null;
          }
     } catch (error) {
          console.log(error);
          return null;
     }
}

export const parseData = (string) => {
     if(string){
          try {
               let newString = JSON.stringify(string);
               try {

                    return JSON.parse(string);
               } catch (error) {
                    console.log(error);
                    return JSON.parse(newString.trim());
               }
          } catch (error) {
               console.log(error);
               return null;
          }
     }else{
          return null;
     }
}