import { useNavigate } from "react-router-dom"

const HomePage = () => {
     const navigate = useNavigate();
     return (
          <div className="page-container">
               <div className="home-hero-section">
                    <h1>Welcome to Huza250 Management System</h1>
                    <button className="action-btn" onClick={() => navigate('/login')}>Login</button>
               </div>
          </div>
     )
}

export default HomePage