import { Route, Routes, useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader"
import { useEffect, useState } from "react";
import { deleteSessionData, getSessionData } from "../utils/storageFunctions";
import { server, staffEndpoints } from "../services";
import PropTypes from 'prop-types'
import AddStaff from "../components/Forms/AddStaff";

const StaffPage = () => {
     const [userInfo, setUserInfo] = useState(null);
     const [staffs, setStaffs] = useState(null);
     const navigate = useNavigate();
     
     const logout = () => {
          deleteSessionData('staff');
          return navigate('/login');

     }

     const fetchUsers = async() => {
          const plts = await server.get(staffEndpoints.staffMember.findAll);
          if(plts && plts.data) {
               setStaffs(plts.data);
          }
     }

     const fetchUserInfo = () => {
          const userData = getSessionData('staff');
          if(userData){
               setUserInfo(userData);
          }else{
               navigate('/login');
          }
     }

     useEffect(() => {
          fetchUserInfo();
     },[]) ;

     useEffect(() => {
          (async() => await fetchUsers())();
     },[userInfo]);
     return (
          <div className="page-container">
               <PageHeader content={{title: "Huza250 Staff", user: userInfo, logout}} />
               <Routes>
                    <Route index path="/" element={
                         <div className="staffs-container">
                              <div className="admin-controls-bar">
                                   <span onClick={() => navigate('/staff-management/add')}>Add New</span>
                              </div>
                              {staffs && staffs.length ? 
                                   staffs.map((staff) => <StaffRow key={staff.id} user={staff} />)
                              : <p className="no-data-text">No Staff found</p>}
                         </div>
                    } />
                    <Route path="/add" element={<AddStaff />} />
               </Routes>
               
          </div>
     )
}

const StaffRow = ({user}) => {
     return (
          <div className="staff-row-container">
               <img src={user.profileImage} alt={user.username} className="img" width={40} />
               <span>{user.username}</span>
               <span>{user.userEmail}</span>
               <span>{user.role}</span>
               <span>{user.active ? "Active" : "Inactive"}</span>
               <button className="action-btn">Update</button>
               <buttn className="action-btn">View Actions</buttn>
          </div>
     )
}

StaffRow.propTypes = {
     user: PropTypes.object
}

export default StaffPage