
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainLayout from './main-pages/MainLayout'
import Clickrwanda from './apps/clickrwanda/Clickrwanda'
import RealEstate from './apps/real-estate/RealEstate'

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route index path='/*' element={<MainLayout />}  />
        <Route path='/clickrwanda-dashboard/*' element={<Clickrwanda />} />
        <Route path='/real-estate-dashboard' element={<RealEstate />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
