import { useState } from "react"
import { server, staffEndpoints, uploadInfo } from "../../services";
import { getTimeNow } from "../../utils/dateFuncs";
import { useNavigate } from "react-router-dom";

const AddStaff = () => {
     const [showPassword,setShowPassword] = useState(false);
     const [loading, setLoading] = useState(false);
     const [message, setMessage] = useState({status: false, message: ''});
     const [logo, setLogo] = useState(null);
     const navigate = useNavigate();

     const [permissions, setPermissions] = useState({
          edit: false,
          create: false,
          delete: false
     })
     
     const [staff, setStaff] = useState({});

     const togglePassword = () => {
          if(showPassword){
               setShowPassword(false);
          }else{
               setShowPassword(true);
          }
     }
     const submitForm = async(e) => {
          try {
               setLoading(true);
               e.preventDefault();
               if(logo){
                    const logoUrl = await server.uploadFile(logo, uploadInfo.folders.clickrwanda.temp);
                    console.log(logoUrl);
                    const newStaff ={
                         ...staff,
                         profileImage: logoUrl,
                         permissions,
                         createdAt: getTimeNow(),
                         active: true
                    }
                    const res = await server.post(staffEndpoints.staffMember.save, newStaff);
                    if(res.status){
                         setMessage({status: true, message: "Successfully added the staff"});
                         return navigate('/staff-management');
                    }else{
                         setMessage({status: false, message: res.message});
                    }
               }
          } catch (error) {
               console.log(error)
               setMessage({status: false, message: "Error adding new staff"});

          }finally{
               setLoading(false);
          }
     }
     return (
          <div className="form-container">
               <form className="form auth-form" onSubmit={submitForm}>
                    <div className="group">
                         <label htmlFor="email">Staff Email:</label>
                         <input type="email" name="email" id="email" required onChange={e => setStaff(prev => ({...prev, userEmail: e.target.value}))} />
                    </div>
                    <div className="group">
                         <label htmlFor="username">User name:</label>
                         <input type="text" name="username" id="username" required onChange={e => setStaff(prev => ({...prev, username: e.target.value}))} />
                    </div>
                    <div className="group">
                         <label htmlFor="profileImage">Profile Image:</label>
                         <input type="file" name="profileImage" id="profileImage" onChange={(e) => setLogo(e.target.files[0])} />
                         {logo ? <img src={URL.createObjectURL(logo)} width={50} /> : null}
                    </div>
                    <div className="group">
                         <label htmlFor="password">Password:</label>
                         <input type={showPassword ? "text":"password"} name="password" id="password" onChange={e => setStaff(prev => ({...prev, password: e.target.value}))} />
                         <p className="password-toggler" onClick={togglePassword}>{showPassword ? "Hide" : "Show"}</p>
                    </div>
                    <div className="group">
                         <label htmlFor="role">Role:</label>
                         <select name="role" id="role" onChange={(e) => setStaff(prev => ({...prev, role: e.target.value}))}>
                              <option value="">Select role...</option>
                              <option value="Managing Director">Managing Director</option>
                              <option value="Operations Manager">Operations Manager</option>
                              <option value="Chief Technical Officer">Chief Technical Office</option>
                              <option value="Secretary">Secretary</option>
                              <option value="Sales Executive">Sales Executive</option>
                              <option value="Social media and marketing manager">Social media and marketing Manager</option>
                         </select>
                    </div>
                    <div className="group">
                         <label htmlFor="previleges">Permissions</label>
                         <div className="check-inputs">
                              <input type="checkbox" name="create" id="create" onChange={(e) => setPermissions((prev) => ({...prev, create: e.target.checked}))} />
                              <label htmlFor="create">Create</label>
                              <input type="checkbox" name="edit" id="edit" onChange={(e) => setPermissions((prev) => ({...prev, edit: e.target.checked}))} />
                              <label htmlFor="edit">Edit</label>
                              <input type="checkbox" name="delete" id="delete" onChange={(e) => setPermissions((prev) => ({...prev, delete: e.target.checked}))} />
                              <label htmlFor="delete">Delete</label>
                         </div>
                    </div>
                    <div className="group">
                         <button type="submit">Submit</button>
                    </div>
                    <div className="group">
                    {loading ? <p className="authentication-message">Adding Platform...</p> : null}
                    {message.message.length && !loading ? 
                         <p className={message.status ? 'success-message' : 'fail-message'}>{message.message}</p>
                    : null
                    }
                    </div>
               </form>

          </div>
     )
}

export default AddStaff