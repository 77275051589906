import { useEffect, useState } from "react"
import { server, staffEndpoints } from "../services";
import { getTimeNow } from "../utils/dateFuncs";
import { useNavigate } from "react-router-dom";
import { getSessionData, storeSessionData } from "../utils/storageFunctions";

const LoginPage = () => {
     const [message, setMessage] = useState({status: false, message: ''});
     const [showPassword,setShowPassword] = useState(false);
     const [loading, setLoading] = useState(false);
     const navigate = useNavigate();

     const [data,setData] = useState({});

     const togglePassword = () => {
          if(showPassword){
               setShowPassword(false);
          }else{
               setShowPassword(true);
          }
     }

     const submitForm = async(event) => {
          try {
               setLoading(true);
               event.preventDefault();
               if(Object.entries(data).length){
                    const res = await server.get(`${staffEndpoints.staffMember.authenticate}?email=${data.email}&pwd=${data.password}&date=${getTimeNow()}`);
                    if(res){
                         if(res.status){
                              setMessage({status: true, message: res.message});
                              storeSessionData('staff', res.data);
                              navigate('/manage');
                         }else{
                              setMessage({status: false, message:res.message});
                         }
                    }else{
                         setMessage({status: false, message: "Error connecting to the server"});
                    }
               }else {
                    setMessage({status: false, message: "Please fill in your credentials please!"})
               }
          } catch (error) {
               console.log(error);
               setMessage({status: false, message: "Error connecting to the server"});
          }finally{setLoading(false)}
     }

     useEffect(() => {
          const userData = getSessionData('staff');
          if(userData){
               return navigate('/platforms');
          }
     },[])
     return (
          <div className="page-container">
               <div className="auth-form-container">
                    <form className="auth-form" autoComplete="autoComplete" onSubmit={submitForm}>
                         <div className="group">
                              <label htmlFor="email">User email:</label>
                              <input type="email" name="email" id="email" onChange={(e) => setData(prev => ({...prev, email: e.target.value}))} required />
                         </div>
                         <div className="group">
                              <label htmlFor="password">Password:</label>
                              <input type={!showPassword ? "password" : "text"} name="password" id="password" onChange={(e) => setData(prev => ({...prev, password: e.target.value})) } required />
                              <p className="password-toggler" onClick={togglePassword}>{showPassword ? "Hide" : "Show"}</p>
                         </div>
                         <div className="group">
                              <button className="action-btn" type="submit">Login</button>
                              {loading ? <p className="authentication-message">Authentication in progress...</p>: null}
                              {message.message.length && !loading ? 
                                   <p className={message.status ? 'success-message' : 'fail-message'}>{message.message}</p>
                              : null}
                         </div>
                    </form>
               </div>
          </div>
     )
}

export default LoginPage