import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import '../styles/main.css';
import LoginPage from "./LoginPage";
import PageNotFound from "./PageNotFound";
import PlatformsPage from "./PlatformsPage";
import StaffPage from "./StaffPage";
import StaffPortal from "./StaffPortal";
import Dashboards from "./Dashboards";
import RealEstate from "../apps/real-estate/RealEstate";

const MainLayout = () => {
     return (
          <>
               {/* <Header /> */}
               <Routes>
                    <Route path="/" index element={<HomePage />} />
                    <Route path="/manage/*" element={<StaffPortal />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/platforms/*" element={<PlatformsPage />} />
                    <Route path="/staff-management/*" element={<StaffPage />} />
                    <Route path="/dashboard" element={<Dashboards />} />
                    <Route path="*" element={<PageNotFound />} />
               </Routes>
               <Footer />
          </>
     )
}

export const Header = () => {
     return(
          <div className="home-page-header">
               <h1>Huza250 Ltd</h1>
               <button className="action-btn">Login</button>
          </div>
     )
}

export const Footer = () => {
     return (
          <div className="home-page-footer">
               <p>All rights reserved &copy; 2024. Powered by Huza250 Ltd.</p>
          </div>
     )
}
 
export default MainLayout;