import { useState } from "react"
import { server, staffEndpoints, uploadInfo} from "../../services";
import { useNavigate } from "react-router-dom";

const AddPlatform = () => {
     const [platform, setPlatform] = useState();
     const [logo, setLogo] = useState(null);
     const [loading, setLoading] = useState(false);
     const [message, setMessage] = useState({status: false, message: ''});
     const navigate = useNavigate();

     const submitForm = async (e) => {
          try {
               e.preventDefault();
               setMessage({status: false, message: ""});
               setLoading(true);
               if(logo){
                    const logoUrl = await server.uploadFile(logo, uploadInfo.folders.clickrwanda.temp);
                    if(logoUrl && logoUrl.length){
                         const newPlatform = {
                              ...platform,
                              logo: logoUrl
                         }
                         const res = await server.post(staffEndpoints.platform.save, newPlatform);
                         if(res.status){
                              setMessage({status: true, message: res.message});
                              return navigate('/platforms');
                         }else {
                              return setMessage({status: res.status, message: res.message});
                         }
                    }else {
                         return setMessage({status: false, message: "Error uploading the logo."})
                    }
                    
               }else{
                    return setLoading(false);
               }
          } catch (error) {
               console.log(error);
               setMessage({status: false, message: "Error adding platform"});
          }finally {
               setLoading(false);
          }
          
     }
     
     return (
     <div className="form-container">
          <form className="form" onSubmit={submitForm}>
               <div className="group">
                    <label htmlFor="name">Name: </label>
                    <input type="text" name="name" id="name" required onChange={(e) => setPlatform(prev => ({...prev, name:e.target.value }))} />
               </div>
               <div className="group">
                    <label htmlFor="link">Main Link:</label>
                    <input type="text" name="link" id="link" required onChange={(e) => setPlatform(prev => ({...prev, link:e.target.value }))} />
               </div>
               <div className="group">
                    <label htmlFor="dashboardLink">Dashboard Link:</label>
                    <input type="text" name="dashboardLink" id="dashboardLink" required onChange={(e) => setPlatform(prev => ({...prev, dashboardLink:e.target.value }))} />
               </div>
               <div className="group">
                    <label htmlFor="serverLink">Server Link:</label>
                    <input type="text" name="serverLink" id="serverLink" required onChange={(e) => setPlatform(prev => ({...prev, serverLink:e.target.value }))} />
               </div>
               <div className="group">
                    <label htmlFor="logo">Logo:</label>
                    <input type="file" name="logo" id="logo" required onChange={(e) => setLogo(e.target.files[0])} />
                    {logo ? <img src={URL.createObjectURL(logo)} alt="platform logo" width={50} /> :null}
               </div>
               <div className="group">
                    <label htmlFor="status">Completion Status:</label>
                    <select name="status" id="status" onChange={(e) => setPlatform(prev => ({...prev, status: e.target.value}))}>
                         <option value="">Select status...</option>
                         <option value="Completed">Production</option>
                         <option value="Development">Development</option>
                         <option value="Maintenance">Maintenance</option>
                    </select>
               </div>
               <div className="group">
                    <button type="submit" disabled={loading}>Submit</button>
               </div>
               <div className="group">
               {loading ? <p className="authentication-message">Adding Platform...</p> : null}
               {message.message.length && !loading ? 
                    <p className={message.status ? 'success-message' : 'fail-message'}>{message.message}</p>
               : null
               }
               </div>
          </form>
     </div>
     )
}

export default AddPlatform
