import { useNavigate } from "react-router-dom"

const PageNotFound = () => {
     const navigate = useNavigate();
     const moveback = () => {
          navigate(-1);
     }
     return (
          <div className="page-container">
               PageNotFound
               <p className="navigation-para-link" onClick={moveback} >Go Back</p>
          </div>
     )
}

export default PageNotFound