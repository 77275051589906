import axios from "axios";
const url = import.meta.env.VITE_MAIN_SERVER_URL
const staffUrl = `${url}/api/v1/staff`
const clickrwandaServer = import.meta.env.VITE_CLICKRWANDA_SERVER;

export const server = {
     post: async(endpoint,ops) => {
          try {
               const res = await axios.post(endpoint, ops)
               return res.data;
          } catch (error) {
               if (error.response && error.response.data) {
                    return error.response.data;
               } else {
                    return null;
               }
          }
     },
     get: async (endpoint) => {
          try {
               const res = await axios.get(endpoint);
               return res.data;
          } catch (error) {
               if (error.response && error.response.data) {
                    return error.response.data;
               } else {
                    return null;
               }
          }
     },
     uploadFile: async(file, folder) => {
          try {
               console.log(clickrwandaServer);
               const formData = new FormData();
               formData.append('image', file);
               formData.append('folderName', folder);
               const res = await server.post(uploadInfo.upload.uploadSingle, formData);
               console.log(res);
               if(res && res.status === "pass"){
                    return res.data;
               }else{
                    return "";
               }
          } catch (error) {
               console.log(error);
               return null;
          }
     },
     uploadFiles: async(files, folder) => {
          const fileUrls = [];
          if (files && files.length > 0) {
               const uploadPromises = Array.from(files).map(async (file) => {
                    const fileUrl = await server.uploadFile(file, folder);
                    return fileUrl;
               });
               const resolvedFileUrls = await Promise.all(uploadPromises);
               fileUrls.push(...resolvedFileUrls);
          }
          return fileUrls;
     }
}

export const staffEndpoints = {
     staffMember: {
          save: `${staffUrl}/staff-member/save`,
          authenticate: `${staffUrl}/staff-member/auth`,
          update: `${staffUrl}/staff-member/update`,
          findAll: `${staffUrl}/staff-member/find-all`
     },
     platform: {
          save: `${staffUrl}/platform/save`,
          update: `${staffUrl}/platform/update`,
          findAll: `${staffUrl}/platform/find-all`
     }
}

export const uploadInfo = {
     upload: {
          uploadSingle: `${clickrwandaServer}/api/file-upload/single`,
          uploadMany: `${clickrwandaServer}/api/file-upload/multiple`
     },
     folders: {
          clickrwanda: {
          adverts: 'adverts',
          banners: 'banners',
          categories: 'categories',
          logos: 'logos',
          payPlans: 'pay-plans',
          quotations: 'quotations',
          temp: "temp",
          userCVs: "userCVs"
          },
          platforms: "platforms",

     }
}