import { useEffect, useState } from "react"
import { server, staffEndpoints } from "../services";
import PropTypes from 'prop-types';
import { deleteSessionData, getSessionData } from "../utils/storageFunctions";
import { Route, Routes, useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import AddPlatform from "../components/Forms/AddPlatform";

const PlatformsPage = () => {
     const [platforms,setPlaforms] = useState(null);
     const [userInfo, setUserInfo] = useState(null);
     const navigate = useNavigate();
     
     const fetchPlatforms = async() => {
          const plts = await server.get(staffEndpoints.platform.findAll);
          if(plts && plts.data) {
               setPlaforms(plts.data);
          }
     }

     const logout = () => {
          deleteSessionData('staff');
          return navigate('/login');

     }

     const fetchUserInfo = () => {
          const userData = getSessionData('staff');
          if(userData){
               setUserInfo(userData);
          }else{
               navigate('/login');
          }
     }

     useEffect(() => {
          fetchUserInfo();
     },[]) ;

     useEffect(() => {
          (async() => await fetchPlatforms())();
     },[userInfo]);
     return (
          <div className="page-container">
               <PageHeader content={{title: "Huza250 Platforms", user: userInfo, logout}} />
               <Routes>
                    <Route index path="/" element={
                         <div className="platforms-container">
                              <div className="admin-controls-bar">
                                   <span onClick={() => navigate('/platforms/add-platform')}>Add Platform</span>
                              </div>
                              {
                                   platforms && platforms.length ? 
                                        platforms.map((plt, index) => <PlatformCard key={`platform-card-${index}`} platform={plt} />)
                                   : <p className="no-data-found-text">No platforms recored yet</p>
                              }
                         </div>
                    } />
                    <Route path="/add-platform" element={<AddPlatform />} />
               </Routes>
               
          </div>
     )
}



const PlatformCard = ({platform}) => {
     const navigate = useNavigate();
     return (
          <div className="platform-card">
               <div className="img">
                    <img src={platform.logo} alt="platform logo" width={80}/>
               </div>
               <div className="content">
                    <h2>{platform.name}</h2>
                    <p className="link"><a href={platform.link} target="_blank">Visit</a></p>
                    <div className="buttons">
                         {/* <button className="action-btn">Update</button> */}
                         <button className="action-btn" onClick={() => navigate(`${platform.dashboardLink}`)}>Dashboard</button>
                    </div>
               </div>
          </div>
     )
}

PlatformCard.propTypes = {
     platform: PropTypes.object.isRequired
}

export default PlatformsPage